<template>

<div class="graph__info--mobile__container">
    <div class="graph__info--mobile">
        <span v-for="(ref, refIdx) in props.data.RightLabels" :key="refIdx" :class="`--${ref.Color}`"><i></i>{{ ref.Label }}</span>
    </div>
</div>

<div class="scroll-container">

    <div class="graph__labels">
        <span v-for="(label, labelIdx) in props.data.LeftLabels" :key="labelIdx"
            :style="{ height : (graph.height / props.data.LeftLabels.length) + 'px'}">
            {{label.Name}}
            <label>{{ label.Position }}%</label>
        </span>
        
    </div>

    <div class="graph__container" :style="{ height : graph.height + 'px'}">
            
        <div class="graph__data" :style="{height: '100%', gap: graph.groupGap+'px'}">
            <div class="graph__group" v-for="(group, groupIdx) in props.data.Data" :key="groupIdx" :style="{ height: graph.height - (graph.height / props.data.LeftLabels.length) + 'px' }">
                <div class="graph__group__bars" :style="{gap: graph.barGap +'px'}">
                    <span v-for="(bar, barIdx) in group.Bars" :key="barIdx" 
                        :style="{height: `${bar.Value > 0 ? bar.Value : 1}%`, width: graph.barWidth + 'px'}"
                        :class="`--${bar.Color}`"
                    >
                        {{ bar.Label }}
                    </span>
                </div>
                <div class="graph__group__reference">
                    {{ group.CategoryName }}
                </div>
            </div>

            <div class="graph__bg">
                <div 
                    v-for="(slot, slotIdx) in props.data.LeftLabels.length" :key="slotIdx" 
                    :style="{ height : (graph.height / props.data.LeftLabels.length) + 'px'}">
                </div>
            </div>    
        </div>  <!-- /graph__data -->

    </div> <!-- /graph__container -->
        
    <div class="graph__info">
        <span v-for="(ref, refIdx) in props.data.RightLabels" :key="refIdx" :class="`--${ref.Color}`"><i></i>{{ ref.Label }}</span>
    </div>

</div> <!-- /scroll-container -->


</template>

<script setup>
import { defineProps, onMounted, ref} from 'vue';

const props = defineProps({
    data: Object,
    config: Object
})

const graph = ref({
    height: 240,
    barWidth: 20,
    barGap: 6,
    groupGap: 0,
})

onMounted(()=>{
    for (let clave in props.config) {
        if (clave in graph.value) {
            graph.value[clave] = props.config[clave];
        }
    }
})

</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";

.scroll-container{
    width: 100%;
    max-width: 100%;
    position: relative;
    //height: fit-content;
    //padding: 0 16px 36px 16px;
    display: flex;
    padding: 0 0 36px 0;
    overflow-x: auto;
    overflow-y: visible;
    box-shadow: $boxesShadow;
    border-radius: $cardRadius;
    @media(width < 900px){
        padding-top: 10px;
    }
}


.graph{
    &__container{
        margin-bottom: 30px;
        display: flex;
        width: 100%;
    }
    &__bg{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        >div{
            border-bottom: 1px solid rgba(189, 189, 189, 0.5);
        }
    }
    &__data{
        position: relative;
        width: 100%;
        padding: 0 16px;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
    }
    &__group{
        position: relative;
        z-index: 2;
        &__bars{
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            span{
                padding-bottom: 5px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                font-size: 12px;
                font-weight: 400;
                border-radius: 12px 12px 0 0;
                &.--ROJO{
                    background: $rojo;
                }
                &.--AMARILLO{
                    background: $amarillo;
                }
                &.--VERDE{
                    background: $verde;
                }
                &.--GRIS{
                    background: $gris;
                }
                &.--CELESTE{
                    background: $celeste;
                }
                &.--ROSA{
                    background: $rosa;
                }
                &.--NARANJA{
                    background: $naranja;
                }
                &.--VERDE_CLARO{
                    background: $verdeClaro;
                }
            }
        }
        &__reference{
            position: relative;
            top: 10px;
            width: 87px;
            min-width: 100%;
            padding-top: 4px;
            font-size: 11px;
            font-weight: 500;
            text-align: center;
        }
    }
}

.graph__labels{ //left labels
        position: sticky;
        left: 0;
        padding: 0 4px 0 22px;
        margin-bottom: -30px;
        display: flex;
        flex-direction: column;
        z-index: 3;
        background: #fff;
        //flex-grow: 1;
        @media(width < 600px){
            width: 120px;
            padding-left: 8px;
        }
        span{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 6px;
            font-size: 10px;
            font-weight: 300;
            label{
                margin-bottom: -1px;
                font-size: 10px;
                font-weight: 300;
                color: #BDBDBD;
            }
        }
    }


.graph__info{ //right lebels
    //min-width: 140px;
    position: sticky;
    right: 0;
    padding: 0 22px 20px 16px;
    margin-bottom: -20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: flex-start;
    gap: 16px;
    background: #fff;
    z-index: 3;
    flex-shrink: 0;
    &--mobile{
        display: none;
        position: absolute;
        bottom: -28px;
        width: 100%;
        padding-bottom: 6px;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;
        z-index: 4;
        &__container{
            width: 100%;
            position: relative;
        }
    }
    & span, &--mobile span{
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 12px;
        font-weight: 400;
        i{
            min-width: 20px;
            height: 20px;
            border-radius: 50%;
        }
        &.--ROJO{
            i{
                background: $rojo;
            }
        }
        &.--AMARILLO{
            i{
                background: $amarillo;
            }
        }
        &.--VERDE{
            i{
                background: $verde;
            }
        }
        &.--GRIS{
            i{
                background: $gris;
            }
        }
        &.--CELESTE{
            i{
                background: $celeste;
            }
        }
        &.--ROSA{
            i{
                background: $rosa;
            }
        }
        &.--NARANJA{
            i{
                background: $naranja;
            }
        }
        &.--VERDE_CLARO{
            i{
                background: $verdeClaro;
            }
        }
    }
    @media(width < 900px){
        display: none;
        &--mobile{
            display: flex;
        }
    }
    @media(width < 600px){
        &--mobile{
            gap: 14px;
            span{
                font-size: 10px;
                gap: 4px;
                i{
                    min-width: 12px;
                    height: 12px;
                }
            }
        }
    }
}


</style>
