<template>

<div class="modal-detail__overlay" v-show="props.show" @click="selectLevel.expand = false">

    <div class="modal-detail breakpoints-container">

        <div class="btn-return">
            <span @click="emit('close')"><i class="material-icons">arrow_back</i>{{ $t('global.back') }}</span>
        </div>
            
        <main v-if="loaded">                

            <div class="select-level">
                <h3>{{ $t('progress.evaluationsFor') }} <span>{{ props.studentName }}</span></h3>
                <div>
                    <span class="selected" @click.stop="selectLevel.expand = !selectLevel.expand">
                        {{ 'Nivel ' + selectLevel.selected.Nivel }} <i class="material-icons">keyboard_arrow_down</i>
                    </span>
                    <div class="options" v-if="selectLevel.expand">
                        <span class="option" v-for="(option, idx) in data.Niveles" :key="idx"
                            @click="selectLevel.selected = option; selectLevel.expand = false"
                            >{{ 'Nivel ' + option.Nivel }}
                        </span>
                    </div>
                </div>
            </div>

            <Graph
                :data="selectLevel.selected.Grafico"
                :config="{ barWidth: 26, barGap: 10, groupGap: 36 }"
            />
 
            <div class="modal-detail__cards">
                <Card v-for="(indicador, idx) in selectLevel.selected.Cards" :key="idx"
                    :data="indicador"
                />
            </div>

        </main>

        <Loader v-else />

    </div>

</div>

</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Graph from '@/views/Seguimiento/components/Graph.vue';
import Card from './CardIndicador.vue';
import Loader from '@/components/Loader.vue';

const store = useStore();
const route = useRoute();

const props = defineProps({
    show: Boolean,
    studentName: String
});

const emit = defineEmits(['close']);

const data = ref(null);
const loaded = ref(false);

const selectLevel = ref({
    expand: false,
    selected: null
})

async function getData(){
    try {
        let req = await store.dispatch('seguimiento_modulos', { alumnoId: route.params.alumnoId, moduloCode: 'EVALUACION_DE_COMPANEROS', type: 'DETALLE' })
        data.value = req.data;
    } catch (error) {
        console.log(error);
        store.dispatch('notifications_create', { text: 'Error al obtener Detalle de competencias.' });
    }
}

onMounted(async()=>{
    await getData();
    selectLevel.value.selected = data.value.Niveles[0];
    loaded.value = true;
})

</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";

.modal-detail{
    h3{
        padding: 0;
    }
    .select-level{
        margin-top: 32px;
    }
}

</style>