{
    "global": {
        "continue": "Continuar",
        "back": "Voltar",
        "filters": {
            "inProgress": "Em andamento",
            "finished": "Finalizado",
            "notStarted": "Não iniciado",
            "all": "Todos",
            "complete": "Completo",
            "incomplete": "Incompleto"
        }
    },
    "navbar": {
        "home": "Começar",
        "profile": "Perfil",
        "logout": "Sair",
        "confirmLogout": "Você tem certeza que quer sair?",
        "cancelBtn": "Cancelar",
        "confirmBtn": "Confirme"
    },
    "login": {
        "title":"Iniciar sessão",
        "startWithGoogle":"Faça login no Google",
        "forgotPassword":"Você esqueceu sua senha?",
        "loginBtn":"Acessar",
        "recoveryPasswordTitle":"Recuperar senha",
        "backToLogin":"Volte ao login",
        "recoveryBtn":"Recuperar",
        "followUs":"Siga-nos",
        "username": "Usuário",
        "password": "Senha"
    },
    "home": {
        "hello": "Olá",
        "whatToDo": "O que você deseja fazer?",
        "goToCampus": "Ir para o CAMPUS",
        "goToRuky": "Ir para RUKY",
        "myCourses": "Meus Cursos",
        "course": "Curso",
        "courseDisclaimer": "Por favor, indique a qual curso você deseja acessar:",
        "selectCourse": "Selecione um curso",
        "selectLabel": "Codigo",
        "assistenceFollowing": "Assistência",
        "progress": "Progresso",
        "assistenceEnter": "Entrar",
        "classContent": "Conteúdo da aula",
        "nextClassWhen": "Sua próxima aula é em",
        "noActivities": "Esta aula não tem atividades para realizar.",
        "noForms": "Não há formulários para mostrar.",
        "table": {
            "thisClassTasks": "TAREFAS DESTA AULA",
            "filters": "Filtros",
            "status": "Estado",
            "headers": {
                "activity": "ATIVIDADE",
                "date": "DATA",
                "status": "ESTADO"
            },
            "apply": "Aplicar",
            "cancel": "Cancelar",
            "enter": "Entrar",
            "see": "Ver",
            "bloqued": "Bloqueado",
            "review": "Revisar",
            "previous": "Anterior",
            "next": "Seguinte",
            "taskStoryTitle": "HISTÓRICO DE TAREFAS"
        },
        "formsTitle":"Pesquisas e formulários para preencher",
        "formsEmpty":"Bom trabalho! Você não tem pesquisas ou formulários pendentes para preencher.",
        "loading":"Carregando...",
        "shureAccesCampus":"Tem certeza que quer ir para o campus?",
        "acceptBtn":"Acessar",
        "cancelBtn":"Cancelar",
        "shureAccessCreadly":"Tem certeza que quer ir para Insgnias?",
        "formOpen":"VER",
        "selectOptionTitle":"Selecione a opção que deseja acessar"
    },
    "profile": {
        "title": "Dados básicos",
        "fullName": "Nome de usuário",
        "photoEdit": "Editar imagem",
        "saveChanges": "Salvar alterações",
        "acceptBtn": "Aceitar",
        "shureSaveChanges":"Tem certeza de que deseja salvar as alterações?",
        "cancelBtn":"Cancelar",
        "saveBtn":"Salvar"
    },
    "dashboard": {
        "emptyState": "Seu curso não tem a modalidade de oficinas atribuída",
        "welcome": "Bem-vindo ao",
        "circleGraph": {
            "yourProgressIn": "Seu progresso no Nível 5",
            "howItWorks": "Como funciona o nível 5?",
            "whatIsThis": "O que é isso?",
            "mandatoryWorkshops": "Oficinas iniciais obrigatórias",
            "chosenWorkshops": "Oficinas escolhidas por você",
            "chosenWorkshopsExplanation": "São as oficinas que você deve realizar em cada etapa para alcançar sua certificação. Você escolhe as oficinas que deseja fazer em cada etapa.",
            "followUpInterviews": "Entrevistas de acompanhamento",
            "bonus": "Bônus",
            "bonusExplanation": "São todas as oficinas adicionais que você realiza em cada etapa. Em cada etapa, você deve realizar pelo menos 3 oficinas."
        },
        "stage": {
            "stage": "Etapa",
            "workshops": "Oficinas",
            "mandatoryInitials": "Iniciais obrigatórios",
            "chosenByYou": "Escolhidos por você",
            "metGoal": "👍 Você alcançou o objetivo",
            "didNotMeetGoal": "😔 Você não alcançou o objetivo",
            "exceededGoal": "🙌 Você superou o objetivo",
            "allWorkshopsAreOptional": "Se você trabalhar todos os workshops são opcionais",
            "bonus": "Bônus",
            "inThisStage": "Nesta etapa, você deve realizar todas as oficinas.",
            "completeRequiredWorkshops": "Complete as oficinas requeridas para poder adicionar oficinas adicionais.",
            "noAdditionalWorkshops": "Nesta etapa, você não realizou oficinas adicionais.",
            "notSignedUpForAnyWorkshop": "Você ainda não se inscreveu em nenhuma oficina.",
            "interview": "Entrevista",
            "complete": "COMPLETO",
            "incomplete": "INCOMPLETO",
            "pending": "PENDENTE"
        },
        "journey": {
            "level": "Nível 5",
            "eachStage": "Em cada etapa, você poderá agendar a entrevista com seu treinador e selecionar as oficinas que deseja realizar.",
            "stage": "ETAPA",
            "interview": "Entrevista",
            "complete": "COMPLETO",
            "incomplete": "INCOMPLETO",
            "pending": "PENDENTE",
            "viewSchedule": "Ver agenda",
            "mandatoryInitialWorkshops": "Oficinas iniciais obrigatórias",
            "workshops": "Oficinas",
            "workshop": "Oficina",
            "stageInfo": {
                "openMandatory": "Lembre-se de que, nesta etapa, você deve realizar todas as oficinas para alcançar sua certificação.",
                "open": "Lembre-se de que, nesta etapa, você deve realizar {count} {workshop} para alcançar sua certificação.",
                "pending": "A oferta de oficinas estará disponível nos primeiros dias da etapa.",
                "working": "Selecione as oficinas que deseja realizar para continuar com sua formação.",
                "closed": "Esta etapa já foi finalizada."
            },
            "modalInterview": {
                "header": "Se você ainda não agendou uma entrevista, pressione CONTINUAR.",
                "body": "Se você já agendou uma entrevista e deseja modificar ou cancelar a reserva, acesse pelo link que aparece no e-mail de confirmação que você recebeu.",
                "cancel": "Cancelar",
                "continue": "Continuar"
            },
            "workshopCard": {
                "obligatory": "Obrigatório",
                "complete": "COMPLETO",
                "incomplete": "INCOMPLETO",
                "selectDayAndTime": "Selecione dia e horário",
                "maxCapacityReached": "Esta oficina atingiu sua capacidade máxima de participantes",
                "noMoreDates": "Não há mais datas disponíveis para esta oficina.",
                "reservedPlace": "Lugar reservado",
                "viewDetail": "Ver detalhe",
                "bookPlace": "Reservar lugar",
                "releasePlace": "Liberar lugar",
                "confirmModal": {
                    "youWish": "Deseja",
                    "reserve": "reservar",
                    "release": "liberar",
                    "yourPlace": "seu lugar na oficina",
                    "ofTheDay": "do dia",
                    "cancel": "Cancelar"
                },
                "loadingDates": "Carregando datas...",
                "rememberDate": "Lembre-se de entrar na data e horário atribuídos à oficina",
                "errorReserve": "Erro ao tentar reservar lugar.",
                "errorRelease": "Erro ao tentar liberar lugar."
            }
        }
    },
    "selfKnowledge": {
        "title": "Eixo Autoconhecimento",
        "description": "Habilidades vinculadas à identificação, compreensão e o manejo das próprias emoções.",
        "categories": {
            "personalImage": {
                "title": "1. Imagem pessoal",
                "description": "Prestar atenção à imagem causada, imagem que projeto com minha postura e minha aparência diante da câmera",
                "improvement": "em relação à última avaliação"
            },
            "selfAwareness": {
                "title": "2. Autoconsciência",
                "description": "Ter consciência de minhas capacidades e limitações e como afetam meu comportamento.",
                "improvement": "em relação à última avaliação"
            }
        },
        "indicators": "INDICADORES",
        "level": "NÍVEL",
        "auto": "AUTO",
        "facilitator": "FACILITADOR",
        "evaluation": {
            "whenOnScreen": "Quando estou diante da tela, minha aparência causa uma boa impressão nos outros e é pertinente à situação em que se encontra.",
            "postureAttitude": "Minha postura e atitude diante da câmera expressam interesse e disposição para participar em todas as atividades.",
            "always": "Sempre",
            "frequently": "Frequentemente",
            "sometimes": "Às vezes",
            "rarely": "Muito raramente",
            "never": "Nunca",
            "na": "N/A"
        }
    },
    "learningDiary": {
        "learningDiaries": "DIÁRIOS DE APRENDIZAGEM",
        "whatHaveYouLearned": "O QUE VOCÊ APRENDEU / DESCOBRIU SOBRE SI?",
        "download": "Baixar",
        "viewMore": "Ver mais",
        "learningDiaryHistory": "HISTÓRICO DE DIÁRIOS DE APRENDIZAGEM",
        "responsesOf": "respostas de",
        "questions": "perguntas",
        "class": "AULA",
        "date": "DATA",
        "axis": "EIXO",
        "status": "ESTADO",
        "previous": "Anterior",
        "next": "Seguinte",
        "page": "Página"
    },
    "evaluation": {
        "myAttendance": "MINHA ASSISTÊNCIA",
        "learningDiary": "DIÁRIO DE APRENDIZAGEM",
        "diaryDescription": "É um convite à auto-reflexão em cada aula para ajudá-lo a pensar em sua evolução no programa.",
        "viewDetail": "Ver detalhe",
        "yourEvaluations": "ESTAS SÃO SUAS AVALIAÇÕES",
        "detailByAxis": "DETALHE POR EIXO",
        "selfKnowledge": "Autoconhecimento",
        "selfKnowledgeDescription": "Habilidades vinculadas à identificação, compreensão e o manejo das próprias emoções.",
        "attentionToOthers": "Atenção aos outros e ao ambiente",
        "attentionToOthersDescription": "Habilidades que permitem se relacionar melhor com os outros.",
        "commitmentAndAction": "Compromisso e ação",
        "commitmentAndActionDescription": "Habilidades vinculadas à execução de uma tarefa.",
        "toMaintain": "A manter",
        "toImprove": "A melhorar",
        "personalImage": "Imagem pessoal",
        "selfAwareness": "Autoconsciência",
        "attendanceLabels": {
            "absent": "Ausente",
            "late": "Atrasado",
            "present": "Presente"
        },
        "evaluationLabels": {
            "personalImage": "Imagem pessoal",
            "selfAwareness": "Autoconsciência",
            "empathy": "Empatia",
            "communication": "Comunicação",
            "sociability": "Sociabilidade",
            "autonomy": "Autonomia",
            "responsibility": "Responsabilidade",
            "teamwork": "Trabalho em equipe"
        },
        "evaluationTypes": {
            "selfAssessment": "Autoavaliação",
            "toMaintain": "A manter",
            "toImprove": "A melhorar",
            "toWorkOn": "A trabalhar"
        },
        "chartOptions": {
            "never": "Nunca",
            "rarely": "Pouco",
            "sometimes": "Às vezes",
            "often": "Frequentemente",
            "always": "Sempre"
        }
    },
    "attendanceMonitoring": {
        "back": "Voltar",
        "attendanceMonitoring": "ACOMPANHAMENTO DE ASSISTÊNCIA",
        "download": "Baixar",
        "class": "AULA",
        "date": "DATA",
        "activeParticipants": "PARTICIPANTES ATIVOS",
        "present": "PRESENTES",
        "absent": "AUSENTES",
        "late": "ATRASADOS",
        "detail": "DETALHE",
        "participant": "PARTICIPANTE",
        "status": "ESTADO",
        "modifyStatusHint": "DESEJA MODIFICAR O ESTADO DE UM PARTICIPANTE?",
        "modifyStatusDescription": "Pressione sobre o estado de assistência de cada jovem para modificá-lo.",
        "presentWithNotice": "Presente",
        "absentWithNotice": "Ausente COM aviso",
        "absentWithoutNotice": "Ausente SEM aviso",
        "lateWithNotice": "Atrasado COM aviso",
        "lateWithoutNotice": "Atrasado SEM aviso",
        "comments": "Comentários",
        "send": "Enviar",
        "previous": "Anterior",
        "next": "Seguinte",
        "page": "Página"
    },
    "monitoringActivities": {
        "back": "Voltar",
        "activitiesMonitoring": "ACOMPANHAMENTO DE ATIVIDADES EM AULA",
        "form": "FORMULÁRIO",
        "startDate": "DATA INÍCIO",
        "endDate": "DATA FIM",
        "student": "ALUNO / A",
        "date": "DATA",
        "status": "ESTADO",
        "initiate": "Iniciar",
        "continue": "Continuar",
        "view": "Ver",
        "viewResponse": "Ver resposta",
        "enableEditing": "Habilitar edição",
        "selectClosingDate": "Selecione uma data de fechamento do formulário.",
        "dateLabel": "Data",
        "cancel": "Cancelar",
        "accept": "Aceitar",
        "previousPage": "Anterior",
        "nextPage": "Seguinte",
        "page": "Página"
    },
    "progress": {
        "evaluationsFor": "AVALIAÇÕES PARA",
        "progressOf": "PROGRESSO DE",
        "courseProgress": "PROGRESSO DO CURSO",
        "detailPerStudent": "DETALHE POR PARTICIPANTE",
        "currentLevel": "NÍVEL ATUAL",
        "errorFetchingParticipantData": "Erro ao obter dados do participante.",
        "fromLevel": "DO NÍVEL",
        "table": {
            "name": "PARTICIPANTE",
            "attendance": "PRESENÇA",
            "punctuality": "PONTUALIDADE",
            "diaries": "DIÁRIOS",
            "interviews": "ENTREVISTAS",
            "CV": "CV",
            "seeMore": "Ver mais"
        },           
        "boxCompetencias": {
            "seeDetails": "Ver detalhes",
            "evaluationsCompetencies": "AVALIAÇÕES DE COMPETÊNCIAS",
            "competenciesEvaluationsAllLevels": "AVALIAÇÕES DO TREINADOR - TODOS OS NÍVEIS",
            "competenciesLevelDetail": "AVALIAÇÃO DO TREINADOR E AUTOAVALIAÇÃO - DETALHE POR NÍVEL",
            "competenciesLevelTutorDetail": "AVALIAÇÃO DO TREINADOR - DETALHE POR COMPETÊNCIA E NÍVEL",
            "evaluationTitle": "AVALIAÇÃO DE COMPETÊNCIAS",
            "tutorEvaluations": "Avaliações do treinador",
            "selfEvaluations": "Autoavaliações",
            "viewResults": "Ver resultados",
            "errorFetchingData": "Erro ao obter Avaliação de competências.",
            "noComparative": "Não há comparativo",
            "noVariation": "Sem variações em relação à última avaliação",
            "fromLastEvaluation": "em relação à última avaliação",
            "indicators": "INDICADORES",
            "yourEvaluationAbout": "SUA AVALIAÇÃO SOBRE",
            "level": "NÍVEL",
            "N/A": "N/D" 
        },
        "boxGeneral": {
            "sectionTitle": "GERAL",
            "attendance": "Presença",
            "punctuality": "Pontualidade",
            "learningDiaries": "Diários de aprendizagem",
            "licenses": "Licenças",
            "errorFetchingData": "Erro ao obter dados.",
            "viewResults": "Ver resultados"
        },
        "boxEquipo": {
            "title": "EQUIPE AVALIAÇÃO",
            "recordsLevel": "Fichas de equipe Nível",
            "errorFetchingData": "Erro ao obter Avaliação de Equipe."
        },
        "boxPreparacionLaboral": {
            "title": "PREPARAÇÃO PARA O TRABALHO",
            "betterVersion": "Melhor versão",
            "laboralExperience": "Experiência laboral",
            "formation": "Formação",
            "CVs": "CV's",
            "simulatedInterviews": "Entrevistas simuladas",
            "viewFeedbacks": "Ver devoluções",
            "popup": {
                "title": "Entrevistas simuladas",
                "interview": "Entrevista",
                "Interviewer": "Entrevistador",
                "Punctuality": "Pontualidade",
                "Presence": "Presença",
                "Attitude": "Atitude",
                "NonVerbalCommunication": "Comunicação não verbal",
                "Employable": "Empregável",
                "Strenghts": "Pontos fortes",
                "Weaknesses": "Pontos fracos",
                "Excelente": "Excelente",
                "MuyBien": "Muito Bom",
                "Bien": "Bom",
                "Regular": "Regular",
                "Mal": "Mau"
            }
        }
    },
    "notifications": {
        "INVALID_CREDENTIALS": "Credenciais inválidas",
        "MOODLE_USER_NOT_FOUND" : "O usuário do Campus não está habilitado",
        "data-updated-successfully" : "Os dados foram atualizados com sucesso",
        "cant-login-with-google" : "Ainda não consigo fazer login com o Google, tente novamente",
        "generic-error":"Ocorreu um erro desconhecido",
        "image-updated-successfully":"Foto do perfil atualizada corretamente",
        "image-updated-error":"Ocorreu um erro ao processar a imagem",
        "data-error": "Ocorreu um erro ao carregar os dados.",
        "message-send-ok": "A mensagem foi enviada corretamente.",
        "message-send-error": "Ocorreu um erro ao enviar a mensagem ao aluno.",
        "status-change-ok": "O estado do aluno foi alterado corretamente.",
        "status-change-error": "Ocorreu um erro ao mudar o estado do aluno.",
        "date-change-ok": "A data foi modificada corretamente.",
        "date-change-error": "Ocorreu um erro ao modificar a data de entrega.",
        "login": {
            "emailWillBeSend":"Caso o usuário coincida com o e-mail informado, será enviado um e-mail com a nova senha de acesso",
            "pleaseCompleteEmail":"Preencha o e-mail para recuperar a chave"
        },
        "home": {
            "cannotAccessCreadly":"Não é possível acessar crachás no momento"
        }
    },
    "status": {
        "FINALIZADO": "FINALIZADO",
        "EN_CURSO": "EM ANDAMENTO",
        "SIN_INICIAR": "NÃO INICIADO",
        "COMPLETED": "COMPLETO",
        "INCOMPLETED": "INCOMPLETO",
        "INCOMPLETO": "INCOMPLETO",
        "INICIADO": "INCOMPLETO",
        "COMPLETADO": "COMPLETO",
        "PENDING": "PENDENTE",
        "PENDIENTE": "PENDENTE",
        "0": "NÃO INICIADO",
        "1": "INCOMPLETO",
        "2": "COMPLETO"
    },
    "joven": {
        "status": {
            "FINALIZADO": "COMPLETO",
            "INICIADO": "INCOMPLETO"
        }
    },
    "attendance": {
        "-1": "AUSENTE SEM AVISO",
        "0": "PRESENTE",
        "1": "TARDE SEM AVISO",
        "2": "AUSENTE SEM AVISO",
        "3": "AUSENTE COM AVISO",
        "4": "TARDE COM AVISO",
        "5": "AUSENTE COM AVISO"
    },
    "dias": {
        "0": "Domingo",
        "1": "Segunda-feira",
        "2": "Terça-feira",
        "3": "Quarta-feira",
        "4": "Quinta-feira",
        "5": "Sexta-feira",
        "6": "Sábado"
    },
    "meses": {
        "0": "Janeiro",
        "1": "Fevereiro",
        "2": "Março",
        "3": "Abril",
        "4": "Maio",
        "5": "Junho",
        "6": "Julho",
        "7": "Agosto",
        "8": "Setembro",
        "9": "Outubro",
        "10": "Novembro",
        "11": "Dezembro"
    }
}